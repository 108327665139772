export enum P {
  AGENCY_CREATE = "AGENCY_CREATE",
  AGENCY_FETCH = "AGENCY_FETCH",

  AIRPORT_CREATE = "AIRPORT_CREATE",
  AIRPORT_DELETE = "AIRPORT_DELETE",
  AIRPORT_FETCH = "AIRPORT_FETCH",

  AIRPORT_LOUNGE_BOOKING_CHECK_IN = "AIRPORT_LOUNGE_BOOKING_CHECK_IN",
  AIRPORT_LOUNGE_BOOKING_CREATE = "AIRPORT_LOUNGE_BOOKING_CREATE",
  AIRPORT_LOUNGE_BOOKING_DELETE = "AIRPORT_LOUNGE_BOOKING_DELETE",
  AIRPORT_LOUNGE_BOOKING_EXPORT = "AIRPORT_LOUNGE_BOOKING_EXPORT",
  AIRPORT_LOUNGE_BOOKING_FETCH = "AIRPORT_LOUNGE_BOOKING_FETCH",
  AIRPORT_LOUNGE_BOOKING_READ = "AIRPORT_LOUNGE_BOOKING_READ",
  AIRPORT_LOUNGE_BOOKING_UPDATE = "AIRPORT_LOUNGE_BOOKING_UPDATE",

  AIRPORT_LOUNGE_CREATE = "AIRPORT_LOUNGE_CREATE",
  AIRPORT_LOUNGE_DELETE = "AIRPORT_LOUNGE_DELETE",
  AIRPORT_LOUNGE_FETCH = "AIRPORT_LOUNGE_FETCH",
  AIRPORT_LOUNGE_READ = "AIRPORT_LOUNGE_READ",

  AIRPORT_LOUNGE_TICKET_CREATE = "AIRPORT_LOUNGE_TICKET_CREATE",
  AIRPORT_LOUNGE_TICKET_DELETE = "AIRPORT_LOUNGE_TICKET_DELETE",
  AIRPORT_LOUNGE_TICKET_FETCH = "AIRPORT_LOUNGE_TICKET_FETCH",
  AIRPORT_LOUNGE_TICKET_READ = "AIRPORT_LOUNGE_TICKET_READ",
  AIRPORT_LOUNGE_TICKET_UPDATE = "AIRPORT_LOUNGE_TICKET_UPDATE",
  AIRPORT_LOUNGE_UPDATE = "AIRPORT_LOUNGE_UPDATE",

  AIRPORT_READ = "AIRPORT_READ",
  AIRPORT_UPDATE = "AIRPORT_UPDATE",

  LOUNGE_FARE_CONFIG_CREATE = "LOUNGE_FARE_CONFIG_CREATE",
  LOUNGE_FARE_CONFIG_DELETE = "LOUNGE_FARE_CONFIG_DELETE",
  LOUNGE_FARE_CONFIG_FETCH = "LOUNGE_FARE_CONFIG_FETCH",
  LOUNGE_FARE_CONFIG_READ = "LOUNGE_FARE_CONFIG_READ",
  LOUNGE_FARE_CONFIG_UPDATE = "LOUNGE_FARE_CONFIG_UPDATE",

  SPECIAL_SERVICE_CANCEL = "SPECIAL_SERVICE_CANCEL",
  SPECIAL_SERVICE_CREATE_COVER_NOTE = "SPECIAL_SERVICE_CREATE_COVER_NOTE",
  SPECIAL_SERVICE_CREATE_DRAFT = "SPECIAL_SERVICE_CREATE_DRAFT",
  SPECIAL_SERVICE_EXPORT = "SPECIAL_SERVICE_EXPORT",
  SPECIAL_SERVICE_FETCH = "SPECIAL_SERVICE_FETCH",
  SPECIAL_SERVICE_PAYMENT = "SPECIAL_SERVICE_PAYMENT",
  SPECIAL_SERVICE_READ = "SPECIAL_SERVICE_READ",
  SPECIAL_SERVICE_REFUND = "SPECIAL_SERVICE_REFUND",
  SPECIAL_SERVICE_UPDATE_COVER_NOTE = "SPECIAL_SERVICE_UPDATE_COVER_NOTE",
  SPECIAL_SERVICE_UPDATE_DRAFT = "SPECIAL_SERVICE_UPDATE_DRAFT",

  USER_CHANGE_PW = "USER_CHANGE_PW",
  USER_CREATE = "USER_CREATE",
  USER_DISABLE = "USER_DISABLE",

  USER_EDIT_PER = "USER_EDIT_PER",
  USER_ENABLE = "USER_ENABLE",
  USER_FETCH = "USER_FETCH",
  USER_READ = "USER_READ",
  USER_RESET_PW = "USER_RESET_PW",
  USER_UPDATE = "USER_UPDATE",
}
